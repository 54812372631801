import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Box } from '@material-ui/core'
import { brown, grey } from '@material-ui/core/colors'
import { useSpring, useTransition, useChain, animated} from 'react-spring'

const useStyles = makeStyles(theme => ({
  board: {
    backgroundColor: brown[500],
    borderRadius: '32px',
    boxShadow: `0 5px ${brown[900]}`,
    height: '100%',
    position: 'relative',
    // transform: 'scale(0.8)',
    width: '100%',
  },
  clip: {
    borderBottom: `60px solid ${grey[500]}`,
    borderLeft: '80px solid transparent',
    borderRight: '80px solid transparent',
    height: 0,
    margin: '0 auto',
    position: 'relative',
    transform: 'translate(0, -10px)',
    width: '100px',
    zIndex: 1,
  },
  clipHandle: {
    backgroundColor: grey[500],
    borderRadius: '60px 60px 0 0',
    height: '60px',
    transform: 'translate(0, -59px)',
  },
  clipHandleHole: {
    backgroundColor: theme.palette.primary.main,
    borderRadius: '100%',
    height: '30px',
    margin: 'auto',
    transform: 'translate(0, 20px)',
    width: '30px',
  },
}))

export default function Clipboard({ children }) {
  const classes = useStyles()

  // const props = {}
  const props = useSpring({
    from: {transform: 'rotate(-10deg) translate(-50vw, -100vh) scale(0.8)'},
    to: [
      {transform: 'rotate(0) translate(0, 0) scale(0.8)'},
      {transform: 'rotate(0) translate(0, 0) scale(0.98)'},
    ],
    config: {
      friction: 40,
    },
  })

  return (
    <animated.div className={classes.board} style={props}>
      <div className={classes.clip}>
        <div className={classes.clipHandle}>
          <div className={classes.clipHandleHole} />
        </div>
      </div>
      {children}
    </animated.div>
  )
}
