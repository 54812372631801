import React from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Box, Paper, Avatar, TextField, Typography, Button } from '@material-ui/core';
import AddAPhotoIcon from '@material-ui/icons/AddAPhoto';
import CreateIcon from '@material-ui/icons/Create';

import PageHome from './home/PageHome';

const useStyles = makeStyles(theme => ({
  homeWrapper: {
    background: theme.palette.primary.main,
    height: '100%',
  },
  photoWrapper: {
    // position: 'relative',
    // zIndex: 1,
  },
  one: {
    background: theme.palette.primary.contrastText,
    boxShadow: `0 0 0 3px ${theme.palette.primary.main}`,
    color: theme.palette.primary.main,
    left: '24px',
    position: 'absolute',
    top: '24px',
  },
  photoTextWrapper: {
    background: theme.palette.primary.contrastText,
    borderRadius: '24px',
    height: '40px',
  },
  photoText: {
    color: theme.palette.primary.main,
    paddingTop: '5px',
  },
  photoIcon: {
    color: theme.palette.primary.contrastText,
    fontSize: '5rem',
  },
  uploadButton: {
    backgroundColor: theme.palette.primary.contrastText,
    borderRadius: '40px',
    color: theme.palette.primary.main,
    padding: '8px 80px',
  },
  nameWrapper: {
    background: theme.palette.primary.contrastText,
    position: 'relative',
  },
  bgUpperAngle: {
    background: 'inherit',
    display: 'block',
    height: '50%',
    left: 0,
    position: 'absolute',
    right: 0,
    top: 0,
    transform: 'skewY(-3deg)',
    transformOrigin: '-100%',
  },
  bgLowerAngle: {
    background: 'inherit',
    bottom: 0,
    display: 'block',
    height: '50%',
    left: 0,
    position: 'absolute',
    right: 0,
    transform: 'skewY(3deg)',
    transformOrigin: '-100%',
  },
  nameContents: {
    position: 'relative',
  },
  two: {
    background: theme.palette.primary.main,
    boxShadow: `0 0 0 3px ${theme.palette.primary.contrastText}`,
    color: theme.palette.primary.contrastText,
    position: 'absolute',
    right: '24px',
    top: 0,
  },
  nameTextWrapper: {
    background: theme.palette.primary.main,
    borderRadius: '24px',
    height: '40px',
  },
  nameText: {
    color: theme.palette.primary.contrastText,
    paddingTop: theme.spacing(1),
  },
  nameIcon: {
    color: theme.palette.primary.main,
    fontSize: '5rem',
  },
  nameInputWrapper: {
    width: '64vw',
  },
  nameInputLabel: {
    fontSize: '1.5rem',
    transform: 'translate(0, 32px) scale(1)',
    '&.MuiInputLabel-shrink': {
      transform: 'translate(0, 1.5px) scale(0.75)',
    }
  },
  nameInput: {
    fontSize: '1.5rem',
    marginTop: '24px !important',
  },
  submitWrapper: {
    // position: 'relative',
    // zIndex: 1,
  },
}));

export default function Home() {
  const classes = useStyles();

  return (
    <PageHome />
    // <Grid container direction="column" className={classes.homeWrapper}>
    //   <Grid item>
    //     <Box mb={16} className={classes.photoWrapper}>
    //       <Avatar className={classes.one}>1</Avatar>
    //       <Box pt={6}>
    //         <Box mx={6} className={classes.photoTextWrapper}>
    //           <Typography variant="h5" align="center" className={classes.photoText}>TAKE A PHOTO</Typography>
    //         </Box>
    //       </Box>
    //       <Grid container direction="column" alignItems="center">
    //         <Grid item>
    //           <Box pt={4} ml={-2}>
    //             <AddAPhotoIcon className={classes.photoIcon} />
    //           </Box>
    //         </Grid>
    //         <Grid item>
    //           <Box pt={4}>
    //             <Button
    //               variant="contained"
    //               size="large"
    //               className={classes.uploadButton}
    //             >
    //               Upload
    //             </Button>
    //           </Box>
    //         </Grid>
    //       </Grid>
    //     </Box>
    //   </Grid>
    //   <Grid item>
    //     <Box className={classes.nameWrapper}>
    //       <div className={classes.bgUpperAngle}></div>
    //       <div className={classes.bgLowerAngle}></div>
    //       <div className={classes.nameContents}>
    //         <Box mx={6} className={classes.nameTextWrapper}>
    //           <Typography variant="h5" align="center" className={classes.nameText}>GIVE IT A NAME</Typography>
    //         </Box>
    //         <Avatar className={classes.two}>2</Avatar>
    //         <Grid container direction="column" alignItems="center">
    //           <Grid item>
    //             <Box pt={4} ml={-2}>
    //               <CreateIcon className={classes.nameIcon} />
    //             </Box>
    //           </Grid>
    //           <Grid item>
    //             <Box pt={2} className={classes.nameInputWrapper}>
    //               <TextField
    //                 id="restaurant-name-input"
    //                 label="Restaurant Name"
    //                 name="restaurant-name"
    //                 autoComplete="restaurant-name"
    //                 fullWidth
    //                 InputLabelProps={{
    //                   className: classes.nameInputLabel,
    //                 }}
    //                 InputProps={{
    //                   className: classes.nameInput
    //                 }}
    //               />
    //             </Box>
    //           </Grid>
    //         </Grid>
    //       </div>
    //     </Box>
    //   </Grid>
    //   <Grid item>
    //     <Box pt={12} className={classes.submitWrapper}>
    //       <Grid container direction="column" alignItems="center">
    //         <Grid item>
    //           <Box mt={12} mb={4}>
    //             <Button
    //               variant="contained"
    //               size="large"
    //               className={classes.uploadButton}
    //             >
    //               Divee it up!
    //             </Button>
    //           </Box>
    //         </Grid>
    //         <Grid item>
    //           <Link to="/test">Example</Link>
    //         </Grid>
    //       </Grid>
    //     </Box>
    //   </Grid>
    // </Grid>
  )
}
