import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Grid, Box, Paper, Typography, Avatar, Button, IconButton, TextField,
  Dialog, DialogTitle, DialogContent, DialogActions,
} from '@material-ui/core';
import * as colors from '@material-ui/core/colors';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { useDrop } from 'react-dnd';
import { ItemTypes } from '../Constants';

import { TotalConsumer } from './ReceiptProvider';
import ReceiptColorPicker from './ReceiptColorPicker';
import ReceiptUserItems from './ReceiptUserItems';

export default function ReceiptUser({ items, onEditItem, onRemoveUserFromItems,
                                      user, userTotals, onEditUser, onRemoveUser }) {
  const addItemToUser = () => {
    console.log('dropped!')
  }

  const [{ isOver }, drop] = useDrop({
    accept: ItemTypes.ITEM,
    drop: () => addItemToUser(),
    collect: mon => ({
      isOver: !!mon.isOver(),
    }),
  })

  const [tempColor, setTempColor] = React.useState(user.color);

  const useStyles = makeStyles(theme => ({
    userWrapper: {
      '&:first-of-type': {
        paddingLeft: theme.spacing(8),
      },
      '&:last-of-type': {
        // paddingRight: 0,
        paddingRight: theme.spacing(8),
      },
    },
    userPaper: {
      backgroundColor: isOver ? theme.palette.primary.main : '#fff',
      borderRadius: '20px',
      boxShadow: isOver ? '0 10px 16px 5px rgba(0,0,0,0.25)' : '0 3px 16px 1px rgba(0,0,0,0.15)',
      cursor: 'pointer',
      transform: isOver ? 'translate(0, -10px)' : 'none',
      transition: '.3s ease',
      width: 120,
    },
    userAvatar: {
      backgroundColor: colors[user.color][500],
      color: theme.palette.getContrastText(colors[user.color][500]),
    },
    text: {
      color: isOver ? theme.palette.primary.contrastText : 'rgba(0, 0, 0, 0.87)',
      fontSize: '18px',
      transition: '.3s ease',
    },
    userDialog: {
      '& .MuiPaper-root': {
        minWidth: '90vw',
      },
    },
    userDialogTitle: {
      backgroundColor: colors[tempColor][500],
      color: theme.palette.getContrastText(colors[tempColor][500]),
      padding: `${theme.spacing(2)}px ${theme.spacing(6)}px`,
    },
    userDialogName: {
      paddingTop: theme.spacing(2),
    },
    userEditName: {
      '& > .MuiInput-root': {
        color: theme.palette.getContrastText(colors[tempColor][500]),
        '&:before': {
          borderColor: theme.palette.getContrastText(colors[tempColor][500]),
        }
      }
    },
    userDialogIcons: {
      color: theme.palette.getContrastText(colors[tempColor][500]),
    },
    userDialogSave: {
      backgroundColor: colors[tempColor][500],
      color: theme.palette.getContrastText(colors[tempColor][500]),
    },
    userDialogCancel: {
      color: colors[tempColor][500],
    },
  }));

  const classes = useStyles();

  const [open, setOpen] = React.useState(false);
  const [name, setName] = React.useState(user.name);
  const [selectColor, setSelectColor] = React.useState(false);

  const handleName = () => event => {
    setName(event.target.value);
  }

  const handleDelete = () => {
    onRemoveUserFromItems(user);
    onRemoveUser(user);
    setOpen(false);
  }

  const handleSave = () => {
    onEditUser({
      ...user,
      name: name,
      color: tempColor,
    });
    setOpen(false);
  }

  const handleClose = () => {
    setName(user.name);
    setTempColor(user.color);
    setOpen(false);
  }

  return (
    <Box py={4} pr={4} className={classes.userWrapper}>
      <Paper ref={drop} onClick={() => setOpen(true)} className={classes.userPaper}>
        <Box px={2} py={4}>
          <Grid container direction="column" alignItems="center">
            <Grid item>
              <Avatar alt={user.name} className={classes.userAvatar}>{user.name[0]}</Avatar>
            </Grid>
            <Grid item>
              <Box mt={2}>
                <Typography className={classes.text}>{user.name}</Typography>
              </Box>
            </Grid>
            <Grid item>
              <TotalConsumer>
                {({ userTotals }) => (
                  <Typography className={classes.text}>${userTotals(user).total}</Typography>
                )}
              </TotalConsumer>
            </Grid>
          </Grid>
        </Box>
      </Paper>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="user-dialog-title"
        className={classes.userDialog}
      >
        <DialogTitle id="add-user-dialog-title" className={classes.userDialogTitle}>
          <Grid container>
            <Grid item xs={8} className={classes.userDialogName}>
              <TextField
                id="edit-user-name"
                placeholder="Name"
                name="edit-user-name"
                value={name}
                onChange={handleName()}
                fullWidth
                className={classes.userEditName}
              />
            </Grid>
            <Grid item xs={2}>
              <IconButton onClick={() => setSelectColor(true)} className={classes.userDialogIcons}>
                <EditIcon />
              </IconButton>
              {selectColor ?
                <ReceiptColorPicker
                  selectedColor={tempColor}
                  onSelect={setTempColor}
                  onClose={() => setSelectColor(false)}
                />
                :
                ''
              }
            </Grid>
            <Grid item xs={2}>
              <IconButton onClick={() => handleDelete()} className={classes.userDialogIcons}>
                <DeleteIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent className={classes.userDialogContent}>
          <TotalConsumer>
            {({ userTotals }) => (
              <ReceiptUserItems items={items} user={user} userTotals={userTotals(user)} />
            )}
          </TotalConsumer>
        </DialogContent>
        <DialogActions>
          {/* TODO: Button ripple color */}
          <Button onClick={() => handleSave()} variant="contained" className={classes.userDialogSave} fullWidth autoFocus>
            Save
          </Button>
          <Button onClick={() => handleClose()} className={classes.userDialogCancel} fullWidth>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  )
}
