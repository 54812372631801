import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/styles';

import Header from './components/Header'
import Home from './components/Home'
import Receipt from './components/receipt/Receipt'
import Nav from './components/Nav'

import Theme from './utils/theme';

import './App.css';

function App() {
  return (
    <ThemeProvider theme={Theme}>
      <div className="App">
        <Router>
          {/* <Header /> */}
          <Route path="/" exact component={Home} />
          <Route path="/:id" exact component={Receipt} />
          {/* <Nav /> */}
        </Router>
      </div>
    </ThemeProvider>
  );
}

export default App;
