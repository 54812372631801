import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Grid, Paper, Typography, Drawer, TextField, Button } from '@material-ui/core'
import PersonAddIcon from '@material-ui/icons/PersonAdd';

const useStyles = makeStyles(theme => ({
  paper: {
    borderRadius: '20px',
    boxShadow: '0 3px 16px 1px rgba(0,0,0,0.15)',
    cursor: 'pointer',
    width: 120,
  },
  nameTextField: {
    marginBottom: theme.spacing(8),
  },
}));

export default function ReceiptUserNew() {
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);
  const [values, setValues] = React.useState({
    name: '',
  });

  const handleChange = name => event => {
    setValues({ ...values, [name]: event.target.value });
  }

  const handleSave = () => {
    setOpen(false)
  }

  return (
    <Box py={4} pr={8}>
      <Paper onClick={() => setOpen(true)} className={classes.paper}>
        <Box px={2} py={10}>
          <Grid container justify="center" alignItems="center">
            <Grid item>
              <PersonAddIcon fontSize="large" />
            </Grid>
          </Grid>
        </Box>
      </Paper>
      <Drawer anchor="bottom" open={open} onClose={() => setOpen(false)}>
        <Box p={12}>
          <TextField
            id="add-user-name"
            label="Name"
            name="add-user-name"
            value={values.name}
            onChange={handleChange('name')}
            fullWidth
            className={classes.nameTextField}
          />
          <Grid container spacing={4}>
            <Grid item xs={6}>
              <Button onClick={() => handleSave()} color="primary" variant="contained" fullWidth autoFocus>
                Save
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button onClick={() => setOpen(false)} color="primary" variant="outlined" fullWidth>
                Cancel
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Drawer>
    </Box>
  )
}
