import React from 'react'
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles'
import { Box, Grid, Typography, Button, Input } from '@material-ui/core'
import { grey } from '@material-ui/core/colors'

const useStyles = makeStyles(theme => ({
  receipt: {
    backgroundColor: '#fff',
    height: '85%',
    margin: '0 auto',
    transform: 'translate(0, -20px)',
    width: '80%',
    '&:before': {
      background: `
        linear-gradient( 45deg, #fff 33.333%, transparent 33.333%, transparent 66.667%, #fff 66.667% ),
        linear-gradient( -45deg, #fff 33.333%, transparent 33.333%, transparent 66.667%, #fff 66.667% )
      `,
      backgroundSize: '8px 20px',
      backgroundPosition: '0 10px',
      top: '-10px',
      content: '""',
      display: 'block',
      height: '10px',
      left: 0,
      position: 'absolute',
      right: 0,
    },
    '&:after': {
      background: `
        linear-gradient( 45deg, transparent 33.333%, #fff 33.333%, #fff 66.667%, transparent 66.667% ),
        linear-gradient( -45deg, transparent 33.333%, #fff 33.333%, #fff 66.667%, transparent 66.667% )
      `,
      backgroundSize: '8px 20px',
      backgroundPosition: '0 -10px',
      bottom: '-10px',
      content: '""',
      display: 'block',
      height: '10px',
      left: 0,
      position: 'absolute',
      right: 0,
    },
  },
  line: {
    backgroundColor: grey[500],
    borderRadius: '24px',
    height: '8px',
  },
  thickLine: {
    backgroundColor: grey[700],
    borderRadius: '24px',
    height: '12px',
  },
  startButton: {
    borderRadius: '60px',
    fontSize: '24px',
  },
}))

export default function HomeReceipt() {
  const classes = useStyles()

  const upperLine = m => {
    return (
      <Grid item>
        <Box mx={m} className={classes.line}></Box>
      </Grid>
    )
  }

  const middleLine = (l, r) => {
    return (
      <Grid container justify="space-between">
        <Grid item xs={l}>
          <Box className={classes.line}></Box>
        </Grid>
        <Grid item xs={r}>
          <Box className={classes.line}></Box>
        </Grid>
      </Grid>
    )
  }

  return (
    <div className={classes.receipt}>
      <Box p={12}>
        <Grid container direction="column" spacing={4}>
          <Grid item>
            <Typography variant="h2" align="center">Divee.io</Typography>
          </Grid>
          {[16, 12, 18].map(m => {
            return upperLine(m)
          })}
          <Grid item>
            <Box mt={6}>
              {middleLine(3, 3)}
            </Box>
          </Grid>
          <Grid item>
            {middleLine(2, 3)}
          </Grid>
          <Grid item>
            {middleLine(3, 2)}
          </Grid>
          <Grid item>
            <Box mt={4}>
              <Grid container>
                <Grid item xs={10}>
                  <Typography>1. Take a photo</Typography>
                </Grid>
                <Grid item xs={2}>
                  <Box mt={2} className={classes.line}></Box>
                </Grid>
                <Grid item xs={10}>
                  <Typography>2. Give it a name</Typography>
                </Grid>
                <Grid item xs={2}>
                  <Box mt={2} className={classes.line}></Box>
                </Grid>
                <Grid item xs={10}>
                  <Typography>3. Divee it up</Typography>
                </Grid>
                <Grid item xs={2}>
                  <Box mt={2} className={classes.line}></Box>
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item>
            <Box mt={4}>
              {middleLine(3, 2)}
            </Box>
          </Grid>
          <Grid item>
            {middleLine(2, 2)}
          </Grid>
          <Grid item>
            {middleLine(2, 2)}
          </Grid>
          <Grid item>
            <Grid container justify="space-between">
              <Grid item xs={4}>
                <Box className={classes.thickLine}></Box>
              </Grid>
              <Grid item xs={3}>
                <Box className={classes.thickLine}></Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Box mt={8}>
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <input
                    type="file"
                    name="image"
                    accept="image/*"
                    capture="environment"
                    id="input-image"
                    style={{ display: 'none' }}
                  />
                  <label htmlFor="input-image">
                    <Button variant="outlined" component="span" fullWidth>Take a photo</Button>
                  </label>
                </Grid>
                <Grid item xs={6}>
                  <input
                    type="file"
                    name="image"
                    accept="image/*"
                    id="input-image"
                    style={{ display: 'none' }}
                  />
                  <label htmlFor="input-image">
                    <Button variant="outlined" component="span" fullWidth>Upload</Button>
                  </label>
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item>
            <Link to="/test">Example</Link>
          </Grid>
        </Grid>
      </Box>
    </div>
  )
}
