import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Grid, IconButton, Button } from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { DndProvider } from 'react-dnd'
// import Backend from 'react-dnd-html5-backend';
import Backend from 'react-dnd-touch-backend';

import ReceiptItems from './ReceiptItems';
import ReceiptUsers from './ReceiptUsers';

const useStyles = makeStyles(theme => ({
  wrapper: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  itemsWrapper: {
    flexGrow: 1,
  },
  continueButton: {
    borderRadius: '40px',
    float: 'right',
    fontSize: '1.3rem',
    '& .MuiButton-endIcon svg': {
      fontSize: '2.1875rem',
    },
  },
}));

export default function PageAddItemsToUsers() {
  const classes = useStyles();

  return (
    <DndProvider backend={Backend}>
      <div className={classes.wrapper}>
        <Box m={4}>
          <IconButton aria-label="back">
            <ChevronLeftIcon fontSize="large" />
          </IconButton>
        </Box>
        <Box mx={8} className={classes.itemsWrapper}>
          <ReceiptItems />
        </Box>
        <ReceiptUsers />
        <Box mx={4} mb={4}>
          <Button
            size="large"
            endIcon={<ChevronRightIcon />}
            className={classes.continueButton}
          >
            Continue
          </Button>
        </Box>
      </div>
    </DndProvider>
  )
}
